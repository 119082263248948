import ajax from '@/utils/ajax.js'

/**
 * 获取广告列表
 * @param {*} params 
 * @returns 
 */
export function getAdvertList(params) {
  return ajax.post('/RegionalOutletsAd/getList',params)
}


/**
 * 获取广告列表
 * @param {*} params 
 * @returns 
 */
 export function editAdverts(params) {
  return ajax.post('/RegionalOutletsAd/editData',params)
}
